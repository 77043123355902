import Layout from 'common/layouts/Layout';
import { TrustPilot } from 'common/trustpilot/TrustPilot';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import { graphql } from 'gatsby';
import { HomeQuery } from 'graphqlTypes';
import {
  Expert,
  Hero,
  Income,
  InvestmentAndDebitCard,
  Plan,
  RightPlace,
  Spend,
} from 'page-components/index';
import { BottomCta, Featured } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface HomeProps {
  data: HomeQuery;
}

const Home: FC<HomeProps> = ({ data }) => (
  <Layout>
    <Navigation />
    <PageWrapper
      background="linear-gradient(
        180deg,
        #dbf0e7 0.34%,
        #ffe8d8 16.35%,
        #dcf1e8 40.86%,
        rgba(220, 241, 232, 0.2) 81.79%,
        #dbf0e7 100%
      )"
    >
      <Hero />
      <Featured />
      <RightPlace />
      <Expert />
      <Plan />
      <Income />
      <Spend />
      <TrustPilot
        template="fullSizeCarousel"
        heading="We've helped more than 50,000 people just like you."
      />
      <InvestmentAndDebitCard data={data} />
      <BottomCta
        heading="You're ready for retirement. Make sure your money is too."
        body="From greater peace of mind to ongoing care, we're ready to lend a hand."
      />
      <Footer data={data} displayDepositAccountDetails />
    </PageWrapper>
  </Layout>
);

export default Home;

export const query = graphql`
  query Home {
    ...InvestmentAndDebitCard
    ...DepositAccountAgreement
  }
`;

export const Head = () => (
  <SEO
    title="Spend Retirement with More | Retirable"
    description="Retirable is the first-of-its-kind retirement planning, investing and spending solution with the ongoing care of a fiduciary advisor."
    imagePath="homepage-featured.png"
  />
);
